import React from "react";
import Seo from "../components/seo";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { Container, Row, Col, Button } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge"
import Slider from 'react-slick';
import {USER_COUNT} from '../constants';
import { scroller } from 'react-scroll';

import SaleProductsList from "../components/SaleProduct/SaleProductsList";
import SaleProductsGroup from "../SaleProduct/SaleProductsGroup";

import star from '../images/mariola/star.svg';
import appLogo from '../images/mariola/app-logo.png';

import apple from '../images/mariola/icons/apple.svg';
import quote from '../images/mariola/icons/quote.svg';

import video1 from '../images/mariola/video/video1.mp4';
import video2 from '../images/mariola/video/video2.mp4';

import calm from "../images/black/calm.png";
import focus from "../images/black/focus.png";
import harmony from "../images/black/harmony.png";

import changeMeal from '../images/mariola/icons/change-meal.svg';
import dietitian from '../images/mariola/icons/dietitian.svg';
import list from '../images/mariola/icons/list.svg';
import menu from '../images/mariola/icons/menu.svg';
import trainer from '../images/mariola/icons/trainer.svg';
import waterBalans from '../images/mariola/icons/water-balans.svg';
import weightScale from '../images/mariola/icons/weight-scale.svg';
import workout from '../images/mariola/icons/workout.svg';


const Page = ({ data }) => {

    const bgImage = convertToBgImage(data.hero.childImageSharp.gatsbyImageData);
    const bgImage2 = convertToBgImage(data.heroMobile.childImageSharp.gatsbyImageData);
    const bgImage3 = convertToBgImage(data.users.childImageSharp.gatsbyImageData);
    const bgImage4 = convertToBgImage(data.map.childImageSharp.gatsbyImageData);

    const productsGroups = [
        new SaleProductsGroup(
            'landing:main:dietAndWorkout',
            'Dieta + trening',
            'Dostęp do planu diety i treningów.',
            'blue'
        ),
        new SaleProductsGroup(
            'landing:main:diet',
            'Dieta',
            'Dostęp tylko do planu diety.',
            'red'
        ),
        new SaleProductsGroup(
            'landing:main:workout',
            'Trening',
            'Dostęp tylko do treningów.',
            'black'
        ),
    ];

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <Layout page="mariola" showMenu={true}>
            <Seo />

            <BackgroundImage
                Tag="section"
                className="mariola1 d-none d-md-block" 
                {...bgImage}
            >
                <Container>
                    <Row>
                        <Col md="7">
                            <span className="tag">Hit 2022</span>
                            <h1>Mariola Bojarska-Ferenc</h1>
                            <p className="white">Treningi dla dojrzałych użytkowników w aplikacji <br/>Diet&nbsp;&amp; Training by Ann</p>

                            <Button
                                color="primary"
                                className="mt-4"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Rozpocznij treningi
                            </Button>
                        </Col>
                    </Row>
                </Container>
    
            </BackgroundImage>

            <BackgroundImage
                Tag="section"
                className="mariola1 d-md-none" 
                {...bgImage2}
            >
                <Container className="text-center">
                    <Row>
                        <Col md="7">
                            <span className="tag">Nowość 2021</span>
                            <h1>Mariola Bojarska-Ferenc</h1>
                            <p className="white">Treningi dla dojrzałych użytkowników w aplikacji <br/>Diet&nbsp;&amp; Training by Ann</p>

                            <Button
                                color="primary"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Rozpocznij treningi
                            </Button>
                        </Col>
                    </Row>
                </Container>
    
            </BackgroundImage>
    
            <section className="mariola2">
                <Container>
                    <Row className="align-items-center">
                        <Col className="text-center" xs={{size: 8, offset: 2, order: 2}} md={{size: 4, offset: 1, order: 1}}>
                            <video
                                className="video img-fluid mt-5 mt-md-0"
                                aria-hidden="true"
                                muted
                                autoPlay
                                playsInline
                                loop
                            >
                                <source src={video1} type="video/mp4" />
                            </video>
                        </Col>
                        <Col className="text-center text-md-left" xs={{size: 12, offset: 0, order: 1}} md={{size: 6, offset: 1, order: 2}}>
                            <h2>Trenerka, która rozumie Twoje potrzeby</h2>
                            <p>Z wiekiem potrzeby organizmu zmieniają się. Trening osób dojrzałych, oprócz kształtowania sylwetki, powinien też uwzględniać elementy takie jak poprawa wydolności serca, układu krążenia, czy wzmocnienie kości.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mariola3 pt-0">
                <Container>
                    <Row className="align-items-center">
                        <Col md="6" className="text-center text-md-left">
                            <h2>Bezpieczne ćwiczenia w domu</h2>
                            <p>Mariola Bojarska-Ferenc udowodni Ci, że nawet jeśli skończyłaś 50 lat, możesz rozpocząć bezpieczne, domowe treningi i zadbać o swoją formę! 8 różnych planów treningowych wraz z ćwiczeniami barre (połączenie baletu, yogi i pilatesu) to coś, co stworzyliśmy specjalnie z myślą o naszych dojrzałych użytkownikach!</p>
                            <Button
                                color="primary"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Rozpocznij treningi
                            </Button>
                        </Col>
                        <Col className="text-center mt-5 mt-md-0" md={{size: 5, offset: 1}}>
                            <GatsbyImage image={data.training.childImageSharp.gatsbyImageData} />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mariola4 pt-0">
                <Container>
                    <GatsbyImage
                        image={data.trainingPrograms.childImageSharp.gatsbyImageData}
                        className="d-none d-md-block" />
                    <GatsbyImage
                        image={data.trainingProgramsMobile.childImageSharp.gatsbyImageData}
                        className="d-md-none" />
                </Container>
            </section>
    

            <BackgroundImage
                Tag="section"
                className="mariola5" 
                {...bgImage3}
            >
                <Container>
                    <Row>
                        <Col md={{size: 8, offset: 2}}>
                            <div className="white-box">
                                <img srcSet={`${appLogo} 100w`} sizes="50px" src={appLogo} className="app-icon" alt="" />
                                <h2><span className="pink">{USER_COUNT} użytkowników</span></h2>
                                <p>Aplikacji Diet &amp; Training by Ann zaufało ponad <br/>3 miliony osób.</p>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        scroller.scrollTo('buy', {
                                            duration: 1000,
                                            smooth: true,
                                            offset: -60,
                                        })
                                    }
                                >
                                    Rozpocznij treningi
                                </Button>
                            </div>
                        </Col>
                    </Row>          
                </Container>
            </BackgroundImage>       

            <section className="mariola6">
                <Container className="text-center">
                    <BackgroundImage
                        {...bgImage4}
                        className="bgimg"
                    >
                        <Row>
                            <Col md={{size: 6, offset: 3}}>
                                <h2 className="mb-5">Wyróżnienia w 24 krajach</h2>
                                <p className="mb-5">Wysokie oceny od użytkowników, idealna jakość nagrań treningów oraz doprecyzowane jadłospisy diet pozwoliły Diet & Training by Ann otrzymać w zeszłym roku kilkadziesiąt wyróżnień od firmy Apple.</p>
                                <img src={apple} alt="" />
                            </Col>
                        </Row>
                    </BackgroundImage>
                </Container>
            </section>
         
            <section className="mariola7">
                <Container className="text-center">
                    <h2 className="text-center mb-5">Użytkownicy o aplikacji</h2>
                    <Slider {...settings}>
                        <div>
                            <div className="panel">
                                <img src={quote} className="quote" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <p>
                                    Ja jestem bardzo zadowolona! Osobiste sympatie
                                    lub jej brak do Pani Lewandowskiej nie mają tu
                                    nic do rzeczy. Liczy się sport…
                                </p>
                                <span className="sign">Magdalena</span>
                            </div>
                        </div>
    
                        <div>
                            <div className="panel">
                                <img src={quote} className="quote" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <p>
                                    Aplikacja prosta w użyciu. Bardzo pomocna lista
                                    zakupów (można iść do sklepu z telefonem i
                                    odhaczać po kolei co już mamy w koszyku)…
                                </p>
                                <span className="sign">Szopeczka21</span>
                            </div>
                        </div>
                        
                        <div>
                            <div className="panel">
                                <img src={quote} className="quote" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <p>
                                    Rewelacja! Proste przepyszne przepisy, zrzucanie
                                    zbędnych kilogramów to sama przyjemność
                                </p>
                                <span className="sign">Barbara</span>
                            </div>
                        </div>
    
                        <div>
                            <div className="panel">
                                <img src={quote} className="quote" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <img src={star} className="star" alt="" />
                                <p>
                                    Proste i smaczne przepisy. Zdrowe i nie
                                    pracochłonne. Jestem bardzo zadowolona. Dziękuję
                                </p>
                                <span className="sign">Ewelina</span>
                            </div>
                        </div>
                    </Slider>

                    <Button
                        color="primary"
                        onClick={() =>
                            scroller.scrollTo('buy', {
                                duration: 1000,
                                smooth: true,
                                offset: -60,
                            })
                        }
                    >
                        Rozpocznij treningi
                    </Button>

                </Container>
            </section>

            <section className="mariola8">
                <Container className="text-center">
                    <h2><span className="pink">Diet &amp; Training</span> to coś więcej, niż tylko ćwiczenia fizyczne</h2>
                    <Row>
                        <Col md={{size: 10, offset: 1}}>
                            <p>Wewnętrzna równowaga jest tym, co pozwala na systematyczne utrzymanie diety i aktywności fizycznej. Dbając o spokój swojego umysłu, zyskujesz jeszcze więcej siły na to, by realizować plany, marzenia i po prostu cieszyć się życiem!</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mariola9 pt-0">
                <Container>
                    <Row className="align-items-center">
                        <Col md="6" className="text-center text-md-left">
                            <h2>Świadomy oddech</h2>
                            <p>Oddech to jedno z najlepszych narzędzi do walki ze stresem: pomaga się uspokoić, skupić na chwili bieżącej lub opanować emocje. Właśnie dlatego oddech warto ćwiczyć i pracować nad tym, żeby był jak najbardziej świadomy.</p>

                            <p>W aplikacji znajdziesz 3 zestawy ćwiczeń, które Ci w tym pomogą: Skupienie, Spokój i Harmonia.</p>

                            <Row className="mt-5">
                                <Col className="text-center">
                                    <img src={focus} className="img-fluid" alt="" />
                                    <p className="small">Skupienie</p>
                                </Col>
                                <Col className="text-center">
                                    <img src={calm} className="img-fluid" alt="" />
                                    <p className="small">Spokój</p>
                                </Col>
                                <Col className="text-center">
                                    <img src={harmony} className="img-fluid" alt="" />
                                    <p className="small">Harmionia</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="text-center mt-5 mt-md-0" xs={{size: 8, offset: 2}} md={{size: 4, offset: 1}}>
                            <video
                                className="video img-fluid"
                                aria-hidden="true"
                                muted
                                autoPlay
                                playsInline
                                loop
                            >
                                <source src={video2} type="video/mp4" />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mariola10 pt-0">
                <Container>
                    <Row className="align-items-center">
                        <Col className="mt-5 mt-md-0" xs={{size: 8, offset: 2, order: 2}} md={{size: 5, offset: 0, order: 1}}>
                            <GatsbyImage image={data.balance.childImageSharp.gatsbyImageData} />
                        </Col>
                        <Col className="text-center text-md-left" xs={{size: 12, offset: 0, order: 1}} md={{size: 6, offset: 1, order: 2}}>
                            <h2>Balans</h2>
                            <p>Balans to wyjątkowe miejsce w aplikacji Diet & Training by Ann wypełnione nagraniami prosto ze świata przyrody oraz autorską, relaksacyjną muzyką. Dzięki nim w dowolnym momencie możesz poczuć się tak, jakbyś właśnie spacerowała po lesie, siedziała przy ognisku lub była nad brzegiem morza.</p>
                            <p><strong>Przygotowaliśmy dla Ciebie aż 17 wyjątkowych ścieżek!</strong></p>    
                        </Col>
                        
                    </Row>
                </Container>
            </section>

            <section className="mariola11 pt-0">
                <Container>
                    <Row className="align-items-center">
                        <Col className="text-center text-md-left" md="6">
                            <h2>Wygodna dieta</h2>
                            <p>Poznaj nowe, oryginalne smaki i przekonaj się jak smakują tradycyjne dania w nieco innych, zdrowszych wersjach. Dzięki opcjom indywidualnego dopasowania szybko osiągniesz upragnione efekty, a do tego będziesz jeść wyłącznie takie potrawy, na które masz ochotę!</p>    
                        </Col>
                        <Col className="text-center mt-5 mt-md-0" xs={{size: 8, offset: 2}} md={{size: 5, offset: 1}}>
                            <GatsbyImage image={data.diet.childImageSharp.gatsbyImageData} />
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="mariola12 pt-0">
                <Container className="text-center">
                    <h2 className="mb-5">Funkcje, które pokochasz</h2>
                    <Row>
                        <Col xs="6" md="3">
                            <img src={weightScale} className="mb-3" alt="" />
                            <h4>Śledzenie pomiarów</h4>
                            <p className="small">Uzupełniaj swoje pomiary i wzmacniaj motywację</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={trainer} className="mb-3" alt="" />
                            <h4>Opieka trenerów</h4>
                            <p className="small">Napisz do nas, jeśli masz jakieś pytania. Pomożemy!</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={waterBalans} className="mb-3" alt="" />
                            <h4>Nawodnienie</h4>
                            <p className="small">Zyskaj pewność, że Twój organizm jest optymalnie nawodniony</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={menu} className="mb-3" alt="" />
                            <h4>Jadłospisy okolicznościowe</h4>
                            <p className="small">Wielkanoc, Boże Narodzenie, a może walentynki? Mamy coś na każdą okazję!</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={workout} className="mb-3" alt="" />
                            <h4>Ćwiczenia bez sprzętu</h4>
                            <p className="small">Ciężar własnego ciała to wszystko czego potrzebujesz, żeby zrobić efektywny trening</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={changeMeal} className="mb-3" alt="" />
                            <h4>Wymiana posiłków</h4>
                            <p className="small">Wystarczy jedno kliknięcie, by wymienić posiłek na inny i jeść tylko to, co naprawdę lubisz</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={list} className="mb-3" alt="" />
                            <h4>Wygodna lista zakupów</h4>
                            <p className="small">Zakupy z listą to gwarantowana oszczędność czasu oraz pieniędzy</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={dietitian} className="mb-3" alt="" />
                            <h4>Opieka dietetyków</h4>
                            <p className="small">Nasz zespół jest do Twojej dyspozycji przez 7 dni w tygodniu</p>
                        </Col>
                    </Row>

                    <Button
                        color="primary"
                        className="mt-5"
                        onClick={() =>
                            scroller.scrollTo('buy', {
                                duration: 1000,
                                smooth: true,
                                offset: -60,
                            })
                        }
                    >
                        Rozpocznij treningi
                    </Button>
                </Container>
            </section>

            <section className="mariola13 py-0">
                <Container className="text-center">
                    <h2 className="mb-5">Trenerzy Diet &amp; Training by Ann</h2>
                    <Row>
                        <Col md="4">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.ania.childImageSharp.gatsbyImageData} />
                                <h4>Anna<br/>Lewandowska</h4>
                            </div>
                            
                            <p className="pink">Treningi funkcjonalne</p>
                            <p>Wielokrotna medalistka mistrzostw świata, Europy i Polski w karate tradycyjnym. Była reprezentantka Polski w karate. Dyplomowana trenerka fitness i TRX. Z wykształcenia manager sportowy i dietetyk.</p>
                        </Col>
                        <Col md="4" className="mt-5 mt-md-0">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.jj.childImageSharp.gatsbyImageData} />
                                <h4>Joanna<br/>Jędrzejczyk</h4>
                            </div>
                            <p className="pink">Treningi z elementami sztuk walki</p>
                            <p>Reprezentantka Polski w boksie, kick-boxingu, muay thai oraz mieszanych sztukach walki (MMA). Trzykrotna zawodowa mistrzyni świata. Pierwsza Polka w prestiżowej organizacji Ultimate Fighting Championship.</p>
                        </Col>
                        <Col md="4" className="mt-5 mt-md-0">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.mariola.childImageSharp.gatsbyImageData} />
                                <h4>Mariola<br/>Bojarska-Ferenc</h4>
                            </div>
                            <p className="pink">Treningi dla dojrzałych</p>
                            <p>Absolwentka Akademii Wychowania Fizycznego w Warszawie. Była członkini kadry narodowej w gimnastyce artystycznej. Instruktorka fitness z wieloletnim doświadczeniem, członkini najważniejszych światowych organizacji fitness.</p>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md="4" className="mt-5">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.ewelina.childImageSharp.gatsbyImageData} />
                                <h4>Ewelina<br/>Godlewska</h4>
                            </div>
                            <p className="pink">Yoga</p>
                            <p>Dyplomowana nauczycielka yogi z ponad 10-letnim doświadczeniem. Z wykształcenia psycholog i instruktor rekreacji ruchowej o specjalności ćwiczenia psychofizyczne w oparciu o system hatha yogi.</p>
                        </Col>
                        <Col md="4" className="mt-5">
                            <div className="mx-5 mb-4 position-relative">
                                <GatsbyImage image={data.kuba.childImageSharp.gatsbyImageData} />
                                <h4>Jakub<br/>Głąb</h4>
                            </div>
                            <p className="pink">Treningi dla mężczyzn i par</p>
                            <p>Mistrz Polski w Fitness Sylwetkowym, wielokrotny Mistrz Polski, Europy i Świata w Karate Tradycyjnym. Z zawodu trener personalny, dietetyk, instruktor samoobrony i fitness, certyfikowany trener TRX.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <SaleProductsList
                title={false}
                productsGroups={productsGroups}
                source="mariola">
                    <h2 className="text-center">Rozpocznij <span className="pink">bezpieczne treningi</span> w domu</h2>
            </SaleProductsList>
        </Layout>
    );
}

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "mariola/hero.jpg" }) {...fluidImage1920}
        heroMobile: file(relativePath: { eq: "mariola/hero-mobile.jpg" }) {...fluidImage960}
        trainingPrograms: file(relativePath: { eq: "mariola/training-programs.jpg" }) {...fluidImage1920}
        trainingProgramsMobile: file(relativePath: { eq: "mariola/training-programs-mobile.jpg" }) {...fluidImage960}
        users: file(relativePath: { eq: "mariola/users.jpg" }) {...fluidImage1920}
        map: file(relativePath: { eq: "mariola/map.jpg" }) {...fluidImage1920}
        
        training: file(relativePath: { eq: "mariola/training.jpg" }) {...fluidImage960}
        diet: file(relativePath: { eq: "mariola/diet.jpg" }) {...fluidImage960}
        balance: file(relativePath: { eq: "mariola/balance.jpg" }) {...fluidImage960}

        ania: file(relativePath: { eq: "mariola/ania.jpg" }) {...fluidImage960}
        jj: file(relativePath: { eq: "mariola/jj.jpg" }) {...fluidImage960}
        mariola: file(relativePath: { eq: "mariola/mariola.jpg" }) {...fluidImage960}
        kuba: file(relativePath: { eq: "mariola/kuba.jpg" }) {...fluidImage960}
        ewelina: file(relativePath: { eq: "mariola/ewelina.jpg" }) {...fluidImage960}
    }
`;
